<template>
    <div class="home">
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'SARProxy',
    components: {
    },
    mounted() {
      const blockly = this.$route.query.blockly ? this.$route.query.blockly : '';
      if (blockly) {
        return this.$router.push({ path:'/scripted-rule'});
      }else{
        return this.$router.push({ path:'/arrules', query: {  sar: true }});
      }
    }
  });
  </script>